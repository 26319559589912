import { Injectable, signal } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

export const Roles = {
    Constructer: "constructer",
    Owner: "owner",
    Salesman: "salesman",
    SuperAdmin: "sa-role",
} as const satisfies Record<string, string>;

export type Role = typeof Roles[keyof typeof Roles];
export type Permission = typeof Permissions[number];

export const Permissions = [
    /* ----- order ----- */
    "order:read",
    "order:create",
    "order:delete",
    "order:edit",
    "order:edit_state",

    "order.state:set_in_works",
    "order.state:set_ordered",
    "order.state:set_assembly",
    "order.state:set_complaint",
    "order.state:set_finished",
    "order.state:set_canceled",

    "order.tabs:notes",
    "order.tabs:bussiness",
    "order.tabs:install",
    "order.tabs:protocol",
    "order.tabs:complaints",
    "order.tabs:finance",

    // order note tab
    "order.notes.todo:read",
    "order.notes.todo:create",
    "order.notes.todo:delete",
    "order.notes.todo:edit",
    "order.notes.todo:set_state",

    "order.notes.note:read",
    "order.notes.note:create",
    "order.notes.note:edit",
    "order.notes.note:delete",

    // order bussiness tab
    "order.bussiness.price:read",
    "order.bussiness.price:create",
    "order.bussiness.price:delete",
    "order.bussiness.price:edit",

    "order.bussiness.document:read",
    "order.bussiness.document:create",
    "order.bussiness.document:delete",

    "order.bussiness.contract:read",
    "order.bussiness.contract:create",
    "order.bussiness.contract:edit",
    "order.bussiness.contract:delete",
    "order.bussiness.contract:sign",

    // order install tab
    // i dont think this needs finer permissions
    "order.install:read",
    "order.install:create",
    "order.install:delete",

    // order protocol tab
    "order.protocol:read",
    "order.protocol:create",
    "order.protocol:edit",
    "order.protocol:delete",

    // order complaints tab
    // honestly idk how complaints even work
    "order.complaints:read",
    "order.complaints:edit",
    "order.complaints:set_completed",
    "order.complaints:upload_attachment",
    "order.complaints:delete",

    // order finance tab
    "order.finance:read",
    "order.finance:create",
    "order.finance:edit",
    "order.finance:delete",

    /* ----- customers ----- */
    "customer:read",
    "customer:create",
    "customer:edit",
    "customer:delete",

    "customer.tabs:orders",
    "customer.tabs:notes",
    "customer.tabs:documents",

    // customer note tab
    "customer.note:read",
    "customer.note:create",
    "customer.note:edit",
    "customer.note:delete",

    // customer document tab
    "customer.document:read",
    "customer.document:create",
    "customer.document:delete",

    /* ----- partners ----- */
    "partner:read",
    "partner:create",
    "partner:edit",
    "partner:delete",

    "partner.tabs:orders",
    "partner.tabs:notes",
    "partner.tabs:documents",

    // partner note tab
    "partner.note:read",
    "partner.note:create",
    "partner.note:edit",
    "partner.note:delete",

    // partner document tab
    "partner.documents:read",
    "partner.documents:create",
    "partner.documents:delete",

    /* ----- products ----- */
    "product:read",
    "product:create",
    "product:edit",
    "product:delete",
] as const;

export const RolePermissions = {
    [Roles.SuperAdmin]: [...Permissions],
    [Roles.Owner]: [...Permissions],
    [Roles.Salesman]: [
        /* ----- order ----- */
        "order:read",
        "order:create",
        "order:delete",
        "order:edit",
        "order:edit_state",

        "order.state:set_in_works",
        "order.state:set_ordered",
        "order.state:set_assembly",
        "order.state:set_complaint",
        "order.state:set_finished",
        "order.state:set_canceled",

        "order.tabs:notes",
        "order.tabs:bussiness",
        "order.tabs:install",
        "order.tabs:protocol",
        "order.tabs:complaints",
        "order.tabs:finance",

        // order note tab
        "order.notes.todo:read",
        "order.notes.todo:create",
        "order.notes.todo:delete",
        "order.notes.todo:edit",
        "order.notes.todo:set_state",

        "order.notes.note:read",
        "order.notes.note:create",
        "order.notes.note:edit",
        "order.notes.note:delete",

        // order bussiness tab
        "order.bussiness.document:read",
        "order.bussiness.document:create",
        "order.bussiness.document:delete",

        "order.bussiness.contract:read",
        "order.bussiness.contract:sign",

        // order install tab
        "order.install:read",
        "order.install:create",
        "order.install:delete",

        // order protocol tab
        "order.protocol:read",
        "order.protocol:create",
        "order.protocol:edit",
        "order.protocol:delete",

        // order complaints tab
        "order.complaints:read",
        "order.complaints:set_completed",
        "order.complaints:upload_attachment",
        "order.complaints:edit",
        "order.complaints:delete",

        // order finance tab
        "order.finance:read",

        /* ----- customers ----- */
        "customer:read",
        "customer:create",
        "customer:edit",
        "customer:delete",

        "customer.tabs:orders",
        "customer.tabs:notes",
        "customer.tabs:documents",

        // customer note tab
        "customer.note:create",
        "customer.note:edit",

        // customer document tab
        "customer.document:read",
        "customer.document:create",
        "customer.document:delete",

        /* ----- partners ----- */
        "partner:read",

        "partner.tabs:orders",
        "partner.tabs:notes",
        "partner.tabs:documents",

        // partner note tab
        "partner.note:read",
        "partner.note:create",
        "partner.note:edit",
        "partner.note:delete",

        // partner document tab
        "partner.documents:read",
        "partner.documents:create",
        "partner.documents:delete",

        /* ----- products ----- */
        "product:read",
    ],
    [Roles.Constructer]: [
        "order:edit_state",

        "order.state:set_in_works",
        "order.state:set_ordered",
        "order.state:set_assembly",

        "order.tabs:install",
        "order.install:read",
        "order.install:create",

        "order.tabs:complaints",
        "order.complaints:read",
        "order.complaints:upload_attachment",

        "order.tabs:protocol",
        "order.protocol:read",
        "order.protocol:create",
        "order.protocol:edit",
    ]
} satisfies Record<string, Permission[]>;

@Injectable({
    providedIn: 'root'
})
export class UserPermissionsService {
    private permissions = signal<Permission[]>([]);
    private permissionsObserveable = toObservable(this.permissions);
    public currentRoles = signal<Role[]>([]);

    constructor(
        private readonly authService: AuthenticationService
    ) {
        this.authService.getOIDCUser().subscribe(x => this.onAuthChanged(x));
    }

    private onAuthChanged(info: any) {
        info = info?.info;

        this.permissions.set([]);

        if (!info) return;

        const roles = Object.keys(info["urn:zitadel:iam:org:project:roles"]);
        const permissions = roles.flatMap(x => RolePermissions[x as keyof typeof RolePermissions] ?? []);

        this.currentRoles.set(roles as Role[]);
        this.permissions.set(permissions);
    }

    public hasPermission(permission: Permission | Permission[]) {
        const cperms = this.permissions();
        if (Array.isArray(permission)) {
            return cperms.filter(x => permission.includes(x)).length === cperms.length;
        } else {
            return cperms.includes(permission);
        }
    }

    public watchPermission(permission: Permission | Permission[]) {
        return this.permissionsObserveable.pipe(map(_ => this.hasPermission(permission)));
    }
}